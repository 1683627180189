<template>
  <div class="public-tabs-square">
    <div
      v-for="(item, index) in data"
      :key="index"
      class="tabs-item"
      :class="{active: currentIndex === index}"
      @click="handleTabs(item, index)"
    >
      <p class="tabs-name">{{ item[prop.label] }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PublicTabsSquare',
  props: {
    // 名称
    data: {
      type: Array,
      default: () => []
    },
    // 显示标签字段名，唯一字段名
    prop: {
      type: Object,
      default: () => ({
        id: 'id',
        label: 'name'
      })
    }
  },
  data() {
    return {
      currentIndex: 0 // 当前选中索引值
    }
  },
  methods: {
    // 点击切换菜单
    handleTabs(item, index) {
      this.currentIndex = index
      this.$emit('tabsClick', item, index)
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common';
.public-tabs-square {
  display: flex;
  margin-bottom: 24px;
  .tabs-item {
    border: 1px solid #DCDFE6;
    .transition();
    & + .tabs-item {
      border-left: 0;
    }
    .tabs-name {
      font-size: 24px;
      color: #303133;
      line-height: 32px;
      padding: 14px 20px;
    }
    &:hover {
      cursor: pointer;
      background: @theme;
      .tabs-name {
        color: #ffffff;
      }
    }
    &.active {
      background: @theme;
      .tabs-name {
        color: #ffffff;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .tabs-item {
      .tabs-name {
        font-size: 16px;
        line-height: 28px;
        padding: 8px 12px;
      }
    }
  }
}
</style>
